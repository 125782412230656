.hayhar-page.m1-007,.hayhar-page.m1-009{ 
    .central-box{
        // position: absolute;
        border-radius: .5rem;
        overflow: hidden;
        background-color: #FFF;
        margin: 5% 0 0 56%;
        width: 27rem;
        position: absolute;
        top: 12rem;
        .tips{
            color:#9ca3af ;
            margin: .75rem auto 0;
            text-align: center;
            
        }
        .heading{
            padding:1rem 0;
            background-color: rgb(56, 189, 248);
            text-align: center;
            color: #FFF;
        }
        .form-modal{
            padding: 1.25rem;
            padding-bottom: 0;
            input{
                border: 1px solid rgb(209,213,219);
                -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(209,213,219 .6);
                box-shadow: inset 0px 1px 1px rgba(0, 0, 0, .075) , 0px 0px 8px rgba(209,213,219 .6);
                &:focus{
                    border:2px solid rgb(56, 189, 248);
                }
            }
            .namePass{
                display: flex;	
                justify-content: space-between;
                margin-bottom: 1rem;
                input{
                    width: 49%;
                }
            }
            .error-hit{
                display: flex;
                margin-bottom: 1rem;
                p{
                    color: #ff0000;
                    width: 50%;
                    text-align: left;
                    font-size: 10px;
                }
            }
            .phone-hit-error{
                margin-left: 5.3rem;
            }
            .email-input{
                display: flex;
                margin-bottom: 1rem;
                input{
                    width: 100%;
                }
                
            }
            .phone-number{
                display: flex;
                justify-content: space-between;
                input.phone{
                    width: 20%;
                    color: #9ca3af;
                    cursor: no-drop;
                }
                input.phoneNumber{
                    width: 78%;
                }
            }
        }
        .submit{
            margin: 1rem auto;
            padding: 8px 16px;
            display: flex;
            justify-content: center;
            background-color: #0ea5e9;
            border: none;
            border-radius: .25rem;
            color: #FFF;
            font-size: .95rem;
            &:hover{
                background-color: rgb(56,189,248);
            }
        }
    }
    // 社交按钮
	.social-web{
		position: fixed;
		transform: translate(50%, -50%);
		top: 48%;
		right: -1%;
		.social-item{
			text-decoration: inherit;
			cursor: pointer;
			display: flex;
			align-items: center;
			background-color: rgb(2,132,199);
			border-top-left-radius: 9999px;
			border-bottom-left-radius: 9999px;
			margin: .5rem 0;
			box-shadow: 0 0 #000 , 0 0 #0000 , 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
			padding:1rem;
			white-space:nowrap;
			color: #FFF;
			width: 100%;
			&:hover{
				transform: translate(-90px,0);
				transition-duration:.3s;
			}
			img{
				height: 2.5rem;
				margin-right: .75rem;
			}
		}
	}
}