
.mobile-qrcode{
    display: none;
}
.ant-modal-root {
	top: 0;
    position: fixed;
    margin: auto;
    height: 100%;
    padding-top: 15%;
    text-align: center;
    width: 100%;
    background-color: rgba(0, 0, 0, .9);
    .ant-modal-content {
        width: 400px;
        background: white;
        margin: auto;
        padding: 1rem;
        border-radius: 0.2rem;
    }
    .ant-modal-title {
        margin-top: 1rem;
        font-weight: bold;
    }
	.ant-modal-close {
		display: none;
	}
	.ant-modal-footer {
		display: none;
	}
	.ant-modal {
		width: 100% !important;
	}
    .ant-modal-body {
        color: #333;
        text-align: left;
        margin: 3rem 2rem 1rem 2rem;
    }
	.downloadOk {
        background: #25aae1;
        width: 110px;
        color: white;
        margin: 3rem auto 0 auto ;
        padding: 0.2rem;
        border-radius: 0.3rem;
        text-align: center;
	}
}
.hayhar-page.m1-002,.hayhar-page.m1-003,.hayhar-page.m1-004,.hayhar-page.m1-005{
    .ant-carousel{
        height: 258.484px;    
        position: relative;
        .slick-dots{
            display: flex !important;
            position: absolute;
            text-align: center;
            transition: .3s opacity;
            transform: translate3d(0,0,0);
            z-index: 10;
            // bottom: 20px;
            left: 0;
            top: 90%;
            width: 100%;
            justify-content: center;
            li{
                width: 8px;
                height: 8px;
                background-color: #FFF;
                margin: 0 4px;
                border-radius: 50%;
                position: relative;
                // #00a826
                &.slick-active{
                    background-color:#00a826 ;

                }
                button{
                    background: #000;
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    width: 36px;
                    height: 4px;
                    border-radius: 50%;
                    margin: 0 4px;
                }
            }

        }


        .img_wrap{
            height: 258.484px;
        }
    }
    .partner{
        display: none;
    }
    .copyright-pay-safe{
        display: none;
    }
    .montenegro{
        display: none;
    }
}
.hayhar-middle{
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
}
.register-box{
    width: 50%;
    margin-top: 20px;
    padding: 0 10px;
    .box-head{
        padding: 20px 0;
        border-radius:10px 10px 0 0 ;
        position: relative;
        color: #FFF;
        background-color: #25aae2;
        text-align: center;
        .icon{
            position:absolute;
            top: 1px;
            left: 0;
        }
        .icon2{
            width: 45px;
            height: 45px;
            position:absolute;
            top: 8px;
            left: 8px;
        }
    }
    .registration-content , .qrcode-content{
        border: 1px solid #ccc;
        border-top: 0;
        padding: 24px;
        background-color: #FFF;
        border-radius: 0 0 10px 10px;
        display: flex;
        flex-direction: column;
        .phone-number{
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
        }
        input{
            border: 1px solid #d1d5db;
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
            &.phoneNumber{
                width: 69.97%;
            }
            &.ant-input-disabled.phone{
                width: 24.99%;
            }
        }
    }

    .phone-hit-error {
        margin-left: 31%;
    }
    .phone-hit-error , .email-hit-error , .pass-hit-error , .name-hit-error{
        font-size: 13px;
        color: #e40771;
        min-height: 1.5rem;
        margin-top: 0.5rem;
    }

    .qrcode-content{
        align-items: center;
        .Down,.down-tips{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
        }
        .down-tips{
            p{
                width: 50%;
                text-align: center;
                font-size: 13px;
                color: grey;
                margin-top: 10px;
            }
            justify-content: center;
            align-items: center;
        }
        .btnDownload{
            display: flex;
            padding: 6px 12px;
            margin-bottom: 0;
            color: #333;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.42857143;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            cursor: pointer;
            border-radius: 4px;
            background-color: #25aae2;
            width: 43%;
            justify-content: center;
            align-items: center;
            color: #FFF;
            img{
                width: 24px;
                margin-right: 10px;
            }
        }


        .installation{
            font-size: 14px;
           
            margin: 20px 0px 0  20px;
            li{
                line-height: 1.3;
                margin-bottom: 10px;
            }
        }
    }    
}
@media (max-width:1023px){
    .GOLTT{
        display: none;
    }    
}
.btn-download-pc{
    color: #fff !important;
    background-color: #25aae1;
    border: 1px solid #25aae1;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    padding: 6px 40px;
    width: fit-content;
    margin: 20px auto;
    cursor: pointer;
}

.register-submit{
    margin: 0 auto;
}