// 响应式布局
.hayhar-page,.m2-001,.m2-002,.m2-003,.m2-004,.m2-005,.m2-006{
	.mobile{
		// 中间内容
		position: relative;
		.mobile-central{
			// background: url('/static/images/hayhar/QRP-NUFC-Mobile.jpg') no-repeat center;
			background-size: 100% 100%;
			text-align: center;
			position: absolute;
			top: .2rem;
			width: 100%;
			.text-top{
				h2{
					font-size: 2.25rem;
					line-height: 2.5rem;
					margin-top: 1.25rem;
				}
				p{
					font-size: 1.875rem;
					line-height: 2.25rem;
				}
			}
			
		}
		.central-box{
			// position: absolute;
			border-radius: .5rem;
			overflow: hidden;
			background-color: #FFF;
			margin: 0;
			width: auto;
			.tips{
				color:#9ca3af ;
				margin: .75rem auto 0;
				text-align: center;
				
			}
			.heading{
				padding:1rem 0;
				background-color: rgb(56, 189, 248);
				text-align: center;
				color: #FFF;
			}
		}
		.form-modal{
			padding: 1.25rem;
			input{
				border: 1px solid rgb(209,213,219);
				-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(209,213,219 .6);
				box-shadow: inset 0px 1px 1px rgba(0, 0, 0, .075) , 0px 0px 8px rgba(209,213,219 .6);
				color:#000 ;
				&:focus{
					border:2px solid rgb(56, 189, 248);
				}
			}
			.namePass{
				display: flex;
				justify-content: space-between;
				margin-bottom: 1rem;
				input{
					width: 49%;
				}
			}
			.email-input{
				display: flex;
				margin-bottom: 1rem;
				input{
					width: 100%;
				}
				
			}
			.phone-number{
				display: flex;
				justify-content: space-between;
				input.phone{
					width: 20%;
					color: #9ca3af;
					cursor: no-drop;
				}
				input.phoneNumber{
					width: 78%;
				}
			}
		}
		.submit{
			margin: 1rem auto;
			padding: 8px 16px;
			display: flex;
			justify-content: center;
			background-color: #0ea5e9;
			border: none;
			border-radius: .25rem;
			color: #FFF;
			font-size: .95rem;
			&:hover{
				background-color: rgb(56,189,248);
			}
		}
		
		
		
		
		/* mobile 头部 */
		.web-input-box{
			.ant-btn{
				padding: 0.65rem 1.25rem;
				border: none;
				border-radius: .25rem;
				transition-duration:.2s;
				// 登录
				&.login{
					background-color: #FFF;
					color: rgb(75,85,99,);
				}
				// 立即加入
				&.register{
					background-color: rgb(52,211,153);
					color: #FFF;
					margin-left: 8px;
				}
			}
		}
		
	}
	
	
	
	// 手机模式
	
	@media (max-width:1023px){
		.ant-carousel{
			height: 560px;
		}
		.img_wrap{
			height: 560px;
		}
		.web{
			display: none;
		}
		.mobile{
			display: block;	
			&.top{
				display: flex;
			}
		}
		.social-web{
			position: initial;
			transform: translate(0,0);
			display: flex;
			justify-content: space-between;
			width: 100%;
			
			.social-item{
				display: flex;
				flex-direction: column;
				border-radius: 0;
				margin: 0;
				align-items: center;
				width: 33.333%;
				height: 100%;
				img{
					margin: 0;
					width: 2.5rem;
				}

				&:hover{
					transform:translate(0px, 0);
				}
			}
		}

		.hayhar-middle{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.register-box{
				width: 100%;
			}
		}
		.end-page-list{
			display: flex;
			flex-direction: column;
			line-height:2.5rem;
			align-items: center;
		}

		.footer-content-web .end-page .help-contact{
			display: flex;
			flex-direction: column;
			margin-top: 0;
			.help{
				span{
					border: none;
				}
				
			}
			.contact-box{
				margin-top: 10px;
				
			}

			
		}


		.mobile-qrcode{
			display: block;
		}
		.web-qrcode{
			display: none;
		}
		
		
		
	}
	@media screen and (max-width:600px) {
		.ant-carousel{
			height: 270px;
		}
		.img_wrap{
			height: 270px;
		}
		
		.mobile {
			.mobile-central{
				overflow: hidden;
				.text-top{
					h2{
						font-size: 1.5rem;
						line-height: 2rem;
						margin-top: 0.9375rem;
					}
					p{
						font-size: 1.25rem;
						line-height: 1.75rem;
					}
				}
			}
		}
		.footer-content-web {
			.partner {
				.item{
					width: 100%;
					margin-top: 1.25rem;
					font-size: 1.25rem;
					.footer-image-box-text{
						display: none !important;
					}
					.image-box .default{
						width: 8.125rem;
						height: 8.125rem;
					}
				}
			}
		}
	}
	
	// 600px and 800px
	@media all and (min-width:600px) and (max-width:800px) {
		.mobile {
			.mobile-central{
				overflow: hidden;
			}
		}
	}
}
