// 弹窗提示
.modal{
    position: fixed;
	width: 100%;
	z-index: 1999;
	text-align: center;
    height: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	left: 0;
	top: 0;
	-webkit-transform: translateZ(1px);
	transform: translateZ(1px);
	background-color: rgba(0, 0, 0, 0.4);

    .modal-box{
        position: fixed;
        max-width: 50%;
        width: auto;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        border-radius: .5rem;
        min-width: 400px;
        background-color: #FFF;
        overflow: hidden;
        .header{
            padding: 1.25rem;
            background-color:rgb(14,165,233);
            color: #FFF;
        }
        .center{
            padding: 1.25rem;
        }

        .btn{
            width: 5rem;
            margin: 0 auto;
            margin-bottom: 1.25rem;
            padding: .25rem;
            border-radius: .25rem;
            background-color:rgb(14,165,233) ;
            color: #FFF;
        }
    }

}

 