*{
	margin: 0;
	padding: 0;
}
@import url(./global/index.less);

@import url(./Toast/index.less);
// hayhar QRP page
@import url(./style1/index.less);

@import url(./m1-002/index.less);

@import url(./m1-003/index.less);

@import url(./m1-007/index.less);
// modal
@import url(./hayhar/modal.less);

@import url(./APPHeader/index.less);

@import url(./restrict/index.less);




@hack: true;@import '/buildDir/data/Less/antd-custom.less';